.purchase {
    flex: 1;
    background-color: white;
    margin: 1rem;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
  .purchase-title {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    color: #333;
  }
  

  
  .purchase-form form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .purchase-form__user_input {

    display: flex;
    flex-direction: column;
    /* max-width: 60%; */
    width: 60%;
   
    margin-bottom: 1rem;
    /* background-color: rebeccapurple; */
  }
  
  .purchase-form__user_input label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #555;
  }

  .purchase-form__user_input input{
    padding: 0.7rem;
    border: 1px solid #ccc;
  }
 
  .purchase-form__user_input select {
    width: 100%;
    padding: 0.7rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    /* appearance: none; */
  }
  
  .purchase-form__user_input input[type="number"] {
    -moz-appearance: textfield;
  }
  
  .purchase-form__user_input input:focus,
  .purchase-form__user_input select:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .purchase-form__user_input input::placeholder,
  .purchase-form__user_input select::placeholder {
    color: #aaa;
  }
  
  .purchase-form__register_btn {
    width: 40%;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    background-color: rgb(58, 153, 58);;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s;
    margin-bottom: 1rem;
  }

  .disablebtn{
    opacity: 0.4;
  }
  
  .purchase-form__user_input button:hover {
    background-color: #0056b3;
  }
  
  .purchase-form__user_input datalist option {
    cursor: pointer;
  }
  
  .purchase-form__user_input datalist option:hover {
    background-color: #f0f2f5;
  }
   
 .opacity{
  opacity: 0.4;
 }

 @media (max-width: 768px) {
  .purchase-form{
    width: 100%;
  }
  .purchase-form__user_input{
    width: 90%;
  }
}