.product {
    flex: 1;
    background-color: white;
    margin: 1rem;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    position: relative;
    font-size: 0.8rem;
  }

  .spinner{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

  }

  .product-form__btn__handleclosemodal {
    background-color: rgb(58, 153, 58);
  }
  .product-form__btn__handledeleteandupdate  
    {
    background-color: rgb(58, 153, 58);
    border: 1px solid rgb(58, 153, 58);
  }
  .product-form__btn__handledeleteandupdate:hover{
    background-color: rgb(58, 153, 58);
    border: 1px solid rgb(58, 153, 58);
  }

  .product-form__btn__handledeleteandupdate:active{
    background-color: rgb(58, 153, 58);
    border: 1px solid rgb(58, 153, 58);
  }

  .product-form__pagination{
    background-color: rgb(58, 153, 58);;

  }


  /* @media (max-width: 768px) {
    .product{
        background-color: white;
        margin: 0;
        padding: 1rem 0.1rem;
    }
  } */
  