.App{
  display: flex;
  flex-direction: row;
  background-color: #f0eeee;;
  height: 100vh;
  position: relative;
}


@media (max-width: 768px) {
  .App{
      flex-direction: column;
  }
}