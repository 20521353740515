.viewadmin{
    flex: 1;
    background-color: white;
    margin: 1rem;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    position: relative;
    font-size: 0.8rem;
  
}