/* Sidebar.css */
.sidebar-nav {
    flex: 0 0 25%;
    background-color: rgb(58, 153, 58);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center; 
    overflow-y: auto;
}

.sidebar-nav__profile__firstname{
    text-transform: capitalize;
}
.sidebar-nav__profile__secondname{
    text-transform: capitalize;
}

.sidebar-nav__profile{
    font-size: 3rem;
    font-weight: 700;
    border: 0.4rem solid white;
    padding: 1rem;
    border-radius: 80%;
    margin: 1rem 0rem;
}

.sidebar-nav ul {
    list-style: none;
    padding-inline-start: 0px;
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
    margin-top: 1rem;
    
}

.sidebar-nav__item {
    text-decoration: none;
    text-transform: uppercase;
    color: #faf9f9;
    font-weight: 600;
}

.sidebar-nav__item.active{
    color: rgb(24, 22, 22);
}

.sidebar-nav ul li {
    padding: 0.4rem 0.4rem;
    margin-left: 0.2rem;
}

.sidebar-nav ul li:not(:last-child) {
    margin-bottom: 0.5rem;
}





.admin{ 
    text-transform: lowercase;
    margin-left: 1rem;
    
}

.sidebarproduct{
    text-transform: lowercase;
    margin-left: 1rem;
}

.sidebarpurchase{
    text-transform: lowercase;
    margin-left: 1rem;
}


@media (max-width: 768px) {
    .sidebar-nav{
        flex: 1;
    }

    .sidebar-nav__profile__firstname{
        text-transform: capitalize;
    }
    .sidebar-nav__profile__secondname{
        text-transform: capitalize;
    }

    .sidebar-nav-container{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: start;
        /* position: fixed; */
        /* background-color: red; */
    }

    .sidebar-nav__button{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.6rem;
    }
    
    .sidebar-nav__profile{
        font-size: 1.2rem;
        font-weight: 700;
        border: 0.3rem solid white;
        padding: 0.3rem;
        margin-left: 1.5rem;
        /* border-radius: 80%; */
        /* margin-left: 1.3rem; */
        margin-bottom: 1rem;
        /* margin: 1rem 0rem; */
    }

    .sidebar-ul{
        display: none;
        
    }
    .sideb{
        overflow-y: auto;
    }

    .inactive{
        flex: 0 0 10%;
    }
}