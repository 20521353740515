.login{

        flex: 1;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow-y: hidden;
        overflow-x: hidden;
        font-size: 0.8rem;
        display: flex;
        flex-direction: row;
        max-width: 70%;
        min-width: 70%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-height: 85%;
        min-height: 70%;
}





.login-title{
    /* margin-bottom: 6rem; */
    text-align: center;
    /* font-family: "Seaweed Script", cursive; */
  font-weight: 600;
  font-size: 1.8rem;
  font-style: normal;

  
}


.login-container{
    flex: 0 0 45%;
    padding: 2rem 0rem;
    position: relative;

    
    
}

.login-user{
    width: 70%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    
    
}

.login_data{
    display: flex;
    flex-direction: column;
    width: 100%;
    
     /* border: 1rem solid red; */
}

.login_data input{
    padding: 0.4rem;
    border: 1 solid #ccc;
}


.login-form{

    width: 100%;
    margin-bottom: 1rem;
    
}

.login_btn{
    margin-top: 1rem;
    border: none;
    background-color: rgb(58, 153, 58);
    color: white;
    width: 100%;
    padding: 0.7rem;
}

.login_data_password{
    display: flex;
}

.login_data_password input{
    flex: 1;
        max-width: 100%;
    position: relative;
}

.login_data_password button{
    position: absolute;
    border: none;
    margin-left: -0.6rem;
    border-left: none;
    background-color: transparent;
    cursor: pointer;
    
    right: 5%;
    top: 50%;
}

.login-inventory__photo{
    height: 100%;
    width: 100%;
}


@media (max-width: 768px) {

    .login{
        min-height: 50%;
    }
    .login-inventory{
        display: none;
    }
    .login-container{
        flex: 1;
    }
    .login-title{
        font-weight: 600;
        font-size: 1.4rem;
    }
}
