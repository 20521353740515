.addadmin{
        flex: 1;
        background-color: white;
        margin: 1rem;
        padding: 2rem;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow-y: auto;
        position: relative;
        font-size: 0.8rem;
      
}

.addadmin__form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.addadmin__data{
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-bottom: 1.3rem;
}

.addadmin__data label{
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #555;
}

.addadmin__data input{
    padding: 0.7rem 1rem ;
    border: 1px solid #ccc;
}

.addadmin-form__btn{
    padding: 1rem;
    border: none;
    color: white;
    width: 40%;
    border-radius: 0.3rem;
    background-color: rgb(58, 153, 58);
    cursor: pointer;
    margin-bottom: 1rem;
}