.transaction {
    flex: 1;
    background-color: white;
    margin: 1rem;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    position: relative;
    font-size: 0.8rem;
  }

  .transaction-spinner{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .download-button {
    background-color: #28a745;
    color: #fff;
    border: none;
    /* padding: px 16px; */
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .download-button:hover {
    background-color: #218838;
    text-decoration: none;
    color: #fff;
  }
  

  @media (max-width: 768px) {
    .transaction{
        
        /* margin: 0; */
        padding: 1rem 0rem 0rem 0rem;

    }
  }